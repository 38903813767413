import auth from '../auth/auth'
import axios from 'axios'
import alert from '@/common/alert'
import swal from 'sweetalert2'
import router from '@/router'

const AUTHORIZATION = 'Authorization'
const BEARER = 'Bearer'

function dataAdapter(fn) {
  swal.close()
  return (res) => {
    const response = res.response || res
    fn &&
      fn({
        status: response.status,
        statusText: response.status,
        body: response.data,
        headers: response.headers || (res.config && res.config.headers) || {},
        originalResponse: res,
        paging: response.data.paging,
        error_code: response.error_code,
      })
  }
}

function getTokenHeader() {
  const token = auth.getAccessToken()
  if (!token) return {}
  return {
    [AUTHORIZATION]: BEARER + ' ' + token,
  }
}

function getHeaders(headerParams = {}) {
  return {
    ...getTokenHeader(),
    ...headerParams,
  }
}

export default {
  GET(
    path,
    successHandler,
    errorHandler,
    headerParams = {},
    shouldHandle401 = true,
  ) {
    swal.enableLoading()
    axios
      .get(path, {
        headers: getHeaders(headerParams),
      })
      .then(dataAdapter(successHandler))
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            if (shouldHandle401) {
              auth.resetAccessToken()
              router.push({
                path: '/login',
              })
            } else {
              dataAdapter(errorHandler)
            }
          }
        } else {
          dataAdapter(errorHandler)
        }
      })
  },
  POSTLOGIN(ctx, path, data, successHandler, errorHandler) {
    swal.enableLoading()
    axios
      .post(path, data)
      .then(dataAdapter(successHandler))
      .catch((error) => {
        swal.close()
        if (error.response) {
          if (error.response.status === 401) {
            alert.showErrorAlert(ctx, 'Username atau password anda salah.')
          } else if (error.response.status === 403) {
            alert.showErrorAlert(ctx, 'Anda tidak punya akses ke aplikasi ini.')
          }
        } else {
          dataAdapter(errorHandler)
        }
      })
  },
  POST(
    path,
    data,
    successHandler,
    errorHandler,
    headerParams = {},
    shouldHandle401 = true,
  ) {
    swal.enableLoading()
    axios
      .post(path, data, {
        headers: getHeaders(headerParams),
      })
      .then(dataAdapter(successHandler))
      .catch((error) => {
        swal.close()
        if (error.response) {
          if (error.response.status === 401) {
            if (shouldHandle401) {
              auth.resetAccessToken()
              router.push({
                path: '/login',
              })
            } else {
              dataAdapter(errorHandler)
            }
          }
        } else {
          dataAdapter(errorHandler)
        }
      })
  },
  DELETE(
    path,
    successHandler,
    errorHandler,
    headerParams = {},
    shouldHandle401 = true,
  ) {
    swal.enableLoading()
    axios
      .delete(path, {
        headers: getHeaders(headerParams),
      })
      .then(dataAdapter(successHandler))
      .catch((error) => {
        swal.close()
        if (error.response.status === 401 && shouldHandle401) {
          auth.resetAccessToken()
          this.$router.push({
            path: '/login',
          })
        } else {
          dataAdapter(errorHandler)
        }
      })
  },
}
