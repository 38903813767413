import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import Datepicker from 'vuejs3-datepicker'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(VueSweetalert2)
app.component('Datepicker', Datepicker)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.mount('#app')

app.config.globalProperties.$filters = {
  formatNumber(number, emptyWhenZero = false) {
    if ((number === 0 || isNaN(number)) && emptyWhenZero) {
      return ''
    }

    if (isNaN(number)) {
      return ''
    }

    return Intl.NumberFormat()
      .format(number)
      .replace(',', '.')
      .replace(',', '.')
      .replace(',', '.')
  },
}
