// eslint-disable-next-line no-unused-vars
// const HOST = 'http://127.0.0.1:8080'
// const HOST_V2 = 'http://127.0.0.1:8000/api'
// const HOST_ASSETS = 'http://127.0.0.1:8000'
const HOST = '/backend'
const HOST_V2 = '/backend/v2'
const HOST_ASSETS = '/backend/v2'

export default {
  HOST: HOST,
  HOST_V2: HOST_V2,
  HOST_ASSETS: HOST_ASSETS,
  auth: {
    login: HOST + '/auth/internal-login',
    reset: HOST + '/auth/reset-password/generate',
    challenge: HOST + '/auth/reset-password/challenge',
    silentLogin: HOST + '/auth/silent-login',
    validate: HOST + '/auth/internal-validate',
  },
  devotional: {
    all: HOST + '/devotional/admin/all',
    add: HOST + '/devotional/add',
    detail: HOST + '/devotional/detail/[devotional_id]',
  },
  event: {
    add: HOST + '/event/absence/admin/add',
    get: HOST + '/event/absence/admin/all',
    detail: HOST + '/event/absence/admin/detail',
    checkpoints: HOST + '/event/absence/admin/detail/[event_id]/checkPoints',
    history: HOST + '/event/absence/admin/detail/[event_id]/history',
  },
  group: {
    add: HOST + '/group/',
    members: HOST + '/group/[group_id]/members',
    searchMember: HOST + '/group/search-member',
    connect: HOST + '/group/[group_id]/connect/[member_id]',
    disconnect: HOST + '/group/[group_id]/disconnect/[member_id]',
    connected: HOST + '/group/connected/internal',
  },
  comission: {
    add: HOST + '/ministry/comission',
  },
  attribute: {
    add: HOST + '/ministry/attribute',
    connect: HOST + '/ministry/property/[comission_id]/connect',
    templates: HOST + '/ministry/property/[comission_id]',
  },
  ministry: {
    add: HOST + '/ministry/[comission_id]/add',
    get: HOST + '/ministry/schedule/all',
    detail: HOST + '/ministry/detail-raw/[schedule_id]',
    delete: HOST + '/ministry/schedule/[schedule_id]',
    getPelayan: HOST + '/ministry/schedule/[schedule_id]/pelayan',
    deletePelayan: HOST + '/ministry/schedule/property/[schedule_property_id]',
  },
  room: {
    add: HOST + '/rooms/admin/create',
    detail: HOST + '/rooms/admin/[room_id]',
    get: HOST + '/rooms/get',
    pre_booking: HOST + '/rooms/admin/pre-booking',
    delete_pre_booking: HOST + '/rooms/admin/pre-booking/[pre_booking_id]',
    schedules: HOST + '/rooms/admin/getBookingSchedules',
  },
  widgets: {
    all: HOST + '/widgets/admin/all',
    add: HOST + '/widgets/admin/add',
  },
  content: {
    weeklyVerse: HOST + '/content/internal/weekly-verse',
    wartaJemaat: HOST + '/content/internal/warta-jemaat',
  },
  program: {
    all: HOST_V2 + '/program',
    detail: HOST_V2 + '/program/[program_id]',
    budget: {
      all: HOST_V2 + '/program/[program_id]/budget',
      remaining: HOST_V2 + '/program/[program_id]/budget/remaining',
    },
    pending: HOST_V2 + '/program/pending',
    summary: HOST_V2 + '/program/summary',
    report: HOST_V2 + '/program/report',
  },
  nonProgram: {
    all: HOST_V2 + '/non-program',
    settings: HOST_V2 + '/non-program/settings',
    detail: HOST_V2 + '/non-program/[program_id]',
    report: HOST_V2 + '/non-program/report',
  },
  diaken: {
    all: HOST_V2 + '/diaken',
    detail: HOST_V2 + '/diaken/[connect_id]',
  },
  utility: {
    upload: HOST_V2 + '/upload',
  },
}
