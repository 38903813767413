import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    access_token: null,
    username: null,
    user_id: null,
    is_from_silent_login: false,
    levels: [],
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    setAccessToken(state, access_token) {
      state.access_token = access_token
    },
    clearAccessToken(state) {
      state.access_token = null
      state.levels = []
    },
    setLevels(state, level) {
      state.levels = level
    },
    setUsername(state, username) {
      state.username = username
    },
    setUserID(state, user_id) {
      state.user_id = user_id
    },
    setSilentLogin(state, is_from_silent_login) {
      state.is_from_silent_login = is_from_silent_login
    },
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()],
})
