<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        GKII Admin Hub
      </CHeaderBrand>
      <CHeaderNav v-if="!isFromSilentLogin">
        <div @click="logout">Log out</div>
      </CHeaderNav>
    </CContainer>
  </CHeader>
</template>

<script>
import auth from '@/modules/auth/auth'
export default {
  name: 'AppHeader',
  components: {},
  computed: {
    isFromSilentLogin() {
      return auth.isFromSilentLogin()
    },
  },
  methods: {
    logout() {
      auth.logout()
      location.reload()
    },
  },
}
</script>
