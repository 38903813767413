import api from '../modules/network/base'
import endpoints from '@/modules/network/endpoints'
import store from '../store/index'
import swal from 'sweetalert2'

export default {
  // eslint-disable-next-line no-unused-vars
  login(ctx, username, password, completion) {
    api.POSTLOGIN(
      ctx,
      endpoints.auth.login,
      {
        username: username,
        password: password,
      },
      (resp) => {
        if (resp.body.success === false) {
          completion(false, resp, resp.error_code)
        } else {
          completion(true, resp)
        }
      },
      (resp) => {
        completion(false, null, resp.error_code)
      },
      {},
      false,
    )
  },

  resetPassword(ctx, username, completion) {
    api.POSTLOGIN(
      ctx,
      endpoints.auth.reset,
      {
        username: username,
      },
      (resp) => {
        if (resp.body.success === false) {
          completion(false, resp, resp.error_code)
        } else {
          completion(true, resp)
        }
      },
      (resp) => {
        completion(false, null, resp.error_code)
      },
      {},
      false,
    )
  },

  otpChallenge(ctx, username, password, otp, challengeId, completion) {
    api.POSTLOGIN(
      ctx,
      endpoints.auth.challenge,
      {
        username: username,
        challengeId: challengeId,
        otp: otp,
        password: password,
      },
      (resp) => {
        swal.hideLoading()
        if (resp.body.success === false) {
          completion(false, resp, resp.error_code)
        } else {
          completion(true, resp)
        }
      },
      (resp) => {
        swal.hideLoading()
        completion(false, null, resp.error_code)
      },
      {},
      false,
    )
  },

  silentLogin(ctx, token, completion) {
    api.POSTLOGIN(
      ctx,
      endpoints.auth.silentLogin,
      {
        token: token,
      },
      (resp) => {
        if (resp.body.success === false) {
          completion(false, resp, resp.error_code)
        } else {
          store.commit('setUsername', resp.body.data.username)
          store.commit('setAccessToken', resp.body.data.access_token)
          store.commit('setUserID', resp.body.data.member_id)
          store.commit('setSilentLogin', true)
          this.getConnectedGroups()
          completion(true)
        }
      },
      (resp) => {
        completion(false, null, resp.error_code)
      },
      {},
      false,
    )
  },

  logout() {
    this.resetAccessToken()
  },

  isUserLoggedIn() {
    return store.state.access_token != null
  },

  isDiakenAdmin() {
    var isAuthorized = false
    store.state.levels.forEach((d) => {
      if (['GKII_PROGRAM_KERJA_ADMIN'].includes(d)) {
        isAuthorized = true
        return
      }
    })
    return isAuthorized
  },

  username() {
    return store.state.username
  },

  resetAccessToken() {
    store.commit('setAccessToken', null)
  },

  isUserAuthorized(route, completion) {
    if (!this.isUserLoggedIn()) {
      completion(false)
    }

    this.validateToken((success) => {
      if (!success) {
        completion(false)
      }

      completion(true)
    })
  },

  validateToken(completion) {
    api.GET(
      endpoints.auth.validate,
      async (resp) => {
        if (resp.body.success !== true) {
          this.resetAccessToken()
          completion(false)
        } else {
          completion(true)
        }
      },
      async () => {
        this.resetAccessToken()
        completion(false)
      },
    )
  },

  getConnectedGroups() {
    api.GET(
      endpoints.group.connected,
      (resp) => {
        if (resp.body.success == true) {
          store.commit('setLevels', resp.body.data)
        }
      },
      {},
      {},
      false,
    )
  },
}
