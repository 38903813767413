import store from '../../store/index'
import api from '../network/base'
import endpoints from '../network/endpoints'

export default {
  // eslint-disable-next-line no-unused-vars
  login(username, password, completion) {
    api.POST(
      endpoints.auth.login,
      {
        username: username,
        password: password,
      },
      (resp) => {
        if (resp.body.success === false) {
          completion(false)
        } else {
          store.commit('setUsername', username)
          store.commit('setAccessToken', resp.body.data.access_token)
          store.commit('setLevel', resp.body.data.level)
          completion(true)
        }
      },
      () => {
        completion(false)
      },
      {},
      false,
    )
  },
  logout() {
    this.resetAccessToken()
  },

  username() {
    return store.state.username
  },

  isUserAuthorized(route, completion) {
    console.log(route)
    if (!this.isUserLoggedIn()) {
      completion(false)
    }

    this.validateToken((success) => {
      if (!success) {
        completion(false)
      }

      completion(true)
    })
  },

  isUserLoggedIn() {
    return store.state.access_token != null
  },

  getAccessToken() {
    return store.state.access_token
  },

  isFromSilentLogin() {
    return store.state.is_from_silent_login
  },

  isAdmin() {
    return store.state.level === 'ADMIN'
  },

  isDoctor() {
    return store.state.level === 'DOCTOR'
  },

  isApoteker() {
    return store.state.level === 'APOTIK'
  },

  isFrontdesk() {
    return store.state.level === 'FRONTDESK'
  },

  resetAccessToken() {
    store.commit('setAccessToken', null)
  },

  validateToken(completion) {
    api.GET(
      endpoints.auth.validate,
      async (resp) => {
        if (resp.body.success !== true) {
          this.resetAccessToken()
          completion(false)
        } else {
          completion(true)
        }
      },
      async () => {
        this.resetAccessToken()
        completion(false)
      },
    )
  },
}
