export default {
  showSuccessAlert(ctx, message, didClose) {
    ctx.$swal({
      icon: 'success',
      text: message,
      didClose: didClose,
      showConfirmButton: true,
    })
  },

  showLoading(ctx) {
    ctx.$swal({
      text: 'Mohon menunggu ...',
      showCancelButton: false,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    })
  },

  showGeneralLoading(ctx) {
    ctx.$swal.showLoading()
  },

  hideGeneralLoading(ctx) {
    ctx.$swal.close()
  },

  showErrorAlert(ctx, message, didClose) {
    ctx.$swal({
      icon: 'error',
      text: message,
      didClose: didClose,
      showConfirmButton: true,
    })
  },

  showConfirmationAlert(ctx, message, didConfirm) {
    ctx
      .$swal({
        icon: 'info',
        text: message,
        showConfirmationButton: true,
        showCancelButton: true,
      })
      .then((res) => {
        if (res.value) didConfirm()
      })
  },

  showSuccessToast(ctx, message, didClose) {
    ctx.$swal({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 2000,
      icon: 'success',
      text: message,
      didClose: didClose,
    })
  },
}
