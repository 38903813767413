<template>
  <CFooter>
    <div>
      <span class="ms-1"
        >&copy; 2023 - {{ new Date().getFullYear() }} Gereja Kristen Injili
        Indonesia - IT Team.</span
      >
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
